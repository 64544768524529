import FetchWrapper from './fetch-wrapper.js';
const API = new FetchWrapper( window.origin );
// API.get( endpoint );

function initialiseLikeButtons() { // Sets the state etc for all "like buttons" on the page
	const likeButtons  = document.querySelectorAll('button.likeButton');
	let   likedEntries = [];

	if ( localStorage.getItem('likedEntries') ) {
		likedEntries = JSON.parse( localStorage.getItem('likedEntries') );
	}

	likeButtons.forEach(likeButton => {
		if(!likeButton.dataset.entryId) {
			console.error(`button missing data-entry-id`);
			return null;
		}

		if (likedEntries.includes(likeButton.dataset.entryId)) {
			// The button we're looking at already has its associated entry in localStorage
			likeButton.dataset.state = 'liked';
			likeButton.setAttribute('aria-label', 'Un-like this');
			console.log(likeButton.querySelector('.buttonMessage'));
			likeButton.querySelector('.buttonMessage').textContent = 'Remove property';
		} else {
			likeButton.dataset.state = null;
			likeButton.setAttribute('aria-label', 'Like this');
			likeButton.querySelector('.buttonMessage').textContent = 'Save property';
		}

		likeButton.addEventListener('click', handleLikeButtonClick);
	});
}

function handleLikeButtonClick(event) { // Hand off to the correct action function when clicked
	const clickedButton = event.currentTarget;

	if (!clickedButton.dataset.state || !clickedButton.dataset.entryId) {
		console.error(`Button missing required data attributes`);
		return false;
	}

	if (clickedButton.dataset.state == 'liked') {
		unlike(clickedButton);
	} else {
		like(clickedButton);
	}

	let toggleMyLikes = document.querySelector('.toggleMyLikes');
	toggleMyLikes.classList.add('uc_grabAttention');

	setTimeout(
		() => {
			toggleMyLikes.classList.remove('uc_grabAttention');
		},
		600
	);
}

function like( clickedButton ) { // Do what we need when a button is clicked to store a "like"
	// we know the button has the attributes we need as the handleLikeClick function has enforced that
	const entryId = clickedButton.dataset.entryId;
	let likedEntries = [];

	if ( localStorage.getItem('likedEntries') ) {
		likedEntries = JSON.parse( localStorage.getItem('likedEntries') );
	}

	if( likedEntries.includes( entryId ) ) {
		console.error(`Entry is already liked`);
		return false;
	}

	likedEntries.push( entryId );
	localStorage.setItem('likedEntries', JSON.stringify( likedEntries) );

	clickedButton.dataset.state = 'liked';
	clickedButton.setAttribute('aria-label', `Remove from liked`);
	clickedButton.querySelector('.buttonMessage').textContent = 'Remove property';

	localStorage.setItem('myLikesStatus', 'stale');
	populateMyLikesDrawer();
}

function unlike( clickedButton ) { // Do what we need when a button is clicked to remove a "like"
	// we know the button has the attributes we need as the handleLikeClick function has enforced that
	const entryId = clickedButton.dataset.entryId;
	let likedEntries = [];

	if ( localStorage.getItem('likedEntries') ) {
		likedEntries = JSON.parse( localStorage.getItem('likedEntries') );
	}

	if( ! likedEntries.includes( entryId ) ) {
		console.error(`Entry is not in the liked list`);
		return false;
	}

	likedEntries.splice( likedEntries.indexOf( entryId ), 1 );
	localStorage.setItem('likedEntries', JSON.stringify( likedEntries) );

	clickedButton.dataset.state = null;
	clickedButton.setAttribute('aria-label', `Add to liked`);
	clickedButton.querySelector('.buttonMessage').textContent = 'Save property';

	// let parent = clickedButton.closest('.card');
	// parent.classList.add('justRemoved');

	localStorage.setItem('myLikesStatus', 'stale');
	populateMyLikesDrawer();
}

function populateMyLikesDrawer() {
	let entryIds = JSON.parse( localStorage.getItem('likedEntries') ).join(',');
	console.log(`/my-likes?entryIds=${entryIds}`);

	if (!entryIds) {
		console.log('No entries in local storage');
		return;
	}

	API.getHtml(`/my-likes?entryIds=${entryIds}`).then(response => {
		let responseAsDOM = document.createRange().createContextualFragment(response);
		document.querySelector('#myFavRow .wrapper').replaceChildren(responseAsDOM);

		initialiseLikeButtons(); // Re-initialise, because the loaded content can itself might have like buttons inside
		localStorage.setItem('myLikesStatus', 'fresh');
	});
}

function toggleMyLikesDrawer() {
	let myFavRow = document.querySelector('#myFavRow');
	myFavRow.classList.toggle('active');
}

function initialiseMyLikesDrawer() {
	const showHideToggles = document.querySelectorAll('button.toggleMyLikes');

	localStorage.setItem('myLikesStatus', 'stale'); // initial load ensure state management knows we have no items dynamically loaded yet

	showHideToggles.forEach( toggle => {
		toggle.addEventListener('click', handleMyLikesButtonClick);
	});
}

function handleMyLikesButtonClick() {
	let likedEntries = [];

	if (localStorage.getItem('likedEntries')) {
		likedEntries = JSON.parse(localStorage.getItem('likedEntries'));
	}

	if (
		likedEntries.length > 0
		&&
		localStorage.getItem('myLikesStatus') != 'fresh'
	) {
		console.log('You have items saved, but they need to be loaded via fetch');
		populateMyLikesDrawer();
		toggleMyLikesDrawer();
	} else {
		console.log('just show/hide whatever content is in the div at the moment');
		toggleMyLikesDrawer();
	}
}

initialiseLikeButtons(); // the heart toggles on various Entry listing items
initialiseMyLikesDrawer(); // the panel full of liked items
